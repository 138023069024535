import { Contacts } from '../../pages/secure/dashboard/contacts/Contacts'
import { CreateContact } from '../../pages/secure/dashboard/contacts/CreateContact'
import { CreateDefaultContact } from '../../pages/secure/dashboard/contacts/CreateDefaultContact'
import { DefaultContacts } from '../../pages/secure/dashboard/contacts/DefaultContacts'
import { EditContact } from '../../pages/secure/dashboard/contacts/EditContact'
import { EditDefaultContact } from '../../pages/secure/dashboard/contacts/EditDefaultContact'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const ContactRoutes: IRoute[] = [
  {
    element: <DefaultContacts />,
    path: "contacts/default",
    permission: [ERoles.ADMIN],
    title: "Default contacts",
    showInMenu: true,
  },
  {
    element: <CreateDefaultContact />,
    path: "contacts/default/new",
    permission: [ERoles.ADMIN],
    title: "New default contact",
  },
  {
    element: <EditDefaultContact />,
    path: "contacts/default/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit default contact",
  },
  {
    element: <Contacts />,
    path: "contacts",
    permission: [ERoles.ADMIN],
    title: "Contacts",
    showInMenu: true,
  },
  {
    element: <CreateContact />,
    path: "contacts/new",
    permission: [ERoles.ADMIN],
    title: "New default contact",
  },
  {
    element: <EditContact />,
    path: "contacts/edit/:id",
    permission: [ERoles.ADMIN],
    title: "Edit contact",
  },
]