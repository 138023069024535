import { AuthContext } from '../hooks/useAuth'
import { enumFromStringValue } from '../utils/EnumFromStringValue'
import { ERoles } from '../enums/ERoles'
import { history, routePrefixCustomer, routePrefixDashboard, routePrefixRedirectLogin } from '../ApplicationRouter'
import { IRoute } from '../models/routes/IRoute'
import { useContext } from 'react'

export const isArrayWithLength = (arr: string[]) => {
  return Array.isArray(arr) && arr.length;
};

export const GetAllowedRoutes = (routes: IRoute[]) => {
  const tokenContext = useContext(AuthContext);

  // If the current user isn't logged in, and the current path isn't a redirect path
  if (!tokenContext.role && !window.location.pathname.startsWith(routePrefixRedirectLogin)) {
    var pathSplitted = window.location.pathname.split('/')
    // Only check the route if there actually is a route in the location
    if (pathSplitted.length > 0 && pathSplitted[1] !== '') {

      // Check if the path starts with the default dashboad or customer page
      if (`/${pathSplitted[1]}` === routePrefixDashboard || `/${pathSplitted[1]}` === routePrefixCustomer) {
        // history.replace(`${routePrefixRedirectLogin}${window.location.pathname}${window.location.search}`)
        // TODO: redirect when attempting to access a page that requires authentication https://github.com/auxilium/KJE-NFT/issues/45
        history.replace(`${routePrefixRedirectLogin}`)
      }
    }
  }

  // NOTE: all routes without permission are filtered out
  let allowedRoutes = routes.filter((route: IRoute) => {
    if (!route.permission || !isArrayWithLength(route.permission)) {
      return false;
    } else {
      return tokenContext.role && route.permission.indexOf(enumFromStringValue(ERoles, tokenContext.role)!) > -1;
    }
  });

  return allowedRoutes;
}