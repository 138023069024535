import { AiOutlineDelete } from 'react-icons/ai'
import { Button, Heading, IconButton, Spinner, Text, useToast } from '@chakra-ui/react'
import { DataView, IDataViewColumn } from '../../common/dataview/DataView'
import { FiEdit2 } from 'react-icons/fi'
import { GetDefaultContacts, useDeleteDefaultContactMutation } from '../../../api/calls/contacts/ContactQueries'
import { IDefaultContact } from '../../../models/IContact'
import { NavLink } from 'react-router-dom'
import { useModal } from '../../../context/ModalContext'
import { useQuery, useQueryClient } from 'react-query'
import { useState } from 'react'

export const DefaultContactTable = () => {
  const toast = useToast();
  const { dispatch } = useModal();
  const [columns] = useState<IDataViewColumn[]>([
    { headerName: "name", field: 'name', flex: 1 },
    { headerName: "emailAddress", field: 'email', flex: 1 },
    { headerName: "Actions", cellRenderer: 'actions', flex: 0.5 },
  ]);
  const queryClient = useQueryClient();
  const handleDeleteDefaultContact = useDeleteDefaultContactMutation();
  const { data, isLoading } = useQuery(['contact/default'], GetDefaultContacts);

  const handleDelete = (id: string) => {
    handleDeleteDefaultContact.mutate(id, {
      onSuccess() {
        queryClient.invalidateQueries(["contact/default"])
        dispatch({ type: 'close' })
        toast({
          title: "Default contact Deleted",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error deleting default contact",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  const renderCellAction = (tableData: any) => {
    const defaultContact: IDefaultContact = tableData.data;
    return (
      <>
        <IconButton
          icon={<FiEdit2 fontSize="1.25rem" />}
          aria-label="Edit contact"
          as={NavLink}
          to={`edit/${defaultContact.id}`}
        />
        <IconButton
          icon={<AiOutlineDelete fontSize="1.25rem" />}
          aria-label="Delete contact"
          color="red"
          onClick={() => dispatch({
            type: 'open', payload: {
              title: "Delete default contact",
              bodyContent: <>
                <Text>Are you sure you want to delete the default contact?</Text>
                <Text>This will impact all existing websites.</Text>
              </>,
              footerContent:
                <>
                  <Button mr={3} color="red" onClick={() => handleDelete(defaultContact.id)}>Delete</Button>
                  <Button onClick={() => dispatch({ type: 'close' })}>Close</Button>
                </>
            }
          })}
        />
      </>
    )
  }

  if (isLoading) return <Spinner />
  if (!isLoading && !data) return <Heading as="h1">No default contacts found.</Heading>

  return (
    <DataView
      actionsRenderer={renderCellAction}
      columns={columns}
      data={data}
    />
  )
}