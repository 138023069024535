import { ContactRoutes } from './dashboardSubRoutes/ContactRoutes'
import { GeneralRoutes } from './dashboardSubRoutes/GeneralRoutes'
import { IRoute } from '../models/routes/IRoute'
import { WebsiteRoutes } from './dashboardSubRoutes/WebsiteRoutes'

const dashboardRoutesConfig: IRoute[] = [
  ...GeneralRoutes,
  ...WebsiteRoutes,
  ...ContactRoutes,
];

export default dashboardRoutesConfig;