import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { ContactForm } from '../../../../components/secure/contacts/ContactForm'
import { history } from '../../../../ApplicationRouter'

export const CreateContact = () => {
  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Create contact</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        <ContactForm />
      </Box>
    </AutoWidthCenter>
  )
}