import { BaseProps, FormControl } from './FormControl'
import { CheckboxControl } from './CheckboxControl'
import { Stack, StackProps } from '@chakra-ui/react'
import { useMemo } from 'react'

export interface ICheckboxItem {
  name: string;
  value: string;
  key: string;
}

export type CheckboxContainerProps = BaseProps & {
  stackProps?: StackProps;
  options: ICheckboxItem[];
};

export const CheckboxContainer: React.FC<CheckboxContainerProps> = (
  props: CheckboxContainerProps
) => {
  const { name, label, stackProps, options, ...rest } = props;

  const content = useMemo(() => {
    return options.map((opt) => (
      <CheckboxControl name={name} key={opt.key} value={opt.value} id={`${name}-${opt.key}-${opt.value}`}>{opt.name}</CheckboxControl>
    ))
  }, [options, name])

  return (
    <FormControl name={name} label={label} {...rest}>
      <Stack pl={6} mt={1} spacing={1} {...stackProps}>
        {content}
      </Stack>
    </FormControl>
  );
};

export default CheckboxContainer;