import { AxiosResponse } from 'axios'
import { isLocalhost } from '../../../utils/General'
import { IUserFormLoginValues } from '../../../models/user'
import { loginClient } from '../../base/ApiClients'
import { useMutation } from 'react-query'
/* eslint-disable react-hooks/rules-of-hooks */

async function loginAxios(values: IUserFormLoginValues) {
  return await loginClient.post('/login', values)
}

//Below is a custom hook that will return the useMutation hook for the login
//The hook is called in the LoginForm component and will return the object returned from the useMutation hook
//The useMutation hook could be called inside the loginForm like this:
// const handleLogin = useMutation(newTodo => {
//   return loginClient.post('/todos', newTodo)
// })
//So why a custom hook? Now we have access to a 'general' onSuccess, onError etc and a 'specific'.
//If we used the mutation inside the component we would only have access to the specific onSucces/onError etc
//So in the future if we have a muation that will be called in several places but we always need to do the same interaction after the response is successfull? Put that in de custom hook
//What if we call that mutation but just in 1 place we need to do specific actions? Put that in de component onSuccess

export const useLogin = () => useMutation(loginAxios, {
  // Deze success en error worden elke keer getriggered als de login wordt uitegevoerd.
  // Voor nu niet super nodig maar kan in andere gevallen wel handig zijn.
  // Denk aan, na het uitvoeren van een create wil ik standaard een andere query bijwerken/opnieuw fetchen en een toast tonen.
  onSuccess: (data: AxiosResponse<any, any>, variables: IUserFormLoginValues, context: unknown) => {
    if (isLocalhost()) console.log("Succes vanuit de custom hook! Deze wordt altijd gelogd nadat je deze mutatie gaat uitvoeren.");
  },
  onError(error, variables, context) {
    if (isLocalhost()) {
      console.log("Error! vanuit de custom hook")

      console.log("error", error)
    }
  },
})

