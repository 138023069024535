import { ComponentSingleStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const buttonStyles: ComponentSingleStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700"
  }),
  variants: {
    primary: {
      bgColor: "#9747FF",
      color: "whiteAlpha.900",
    },
    "subtle": (props: StyleFunctionProps) => ({
      color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700",
      backgroundColor: props.colorMode === 'dark' ? "blackAlpha.200" : "gray.200",
    }),
    "link": (props: StyleFunctionProps) => ({
      color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.900"
    }),
    "icon": {
    },
    "ghost": {
      _hover: {
        bgColor: "transparent"
      },
      _active: {
        textDecor: "underline"
      },
      _focus: {
        bgColor: "transparent",
        textDecor: "underline"
      }
    },
    "secondary": (props: StyleFunctionProps) => ({
      bgColor: props.colorMode === 'dark' ? 'gray.400' : 'gray.400',
      color: props.colorMode === 'dark' ? 'primaryWhite' : "primaryBlack"
    }),
  }
}