import { ApplicationRouter } from './ApplicationRouter'
import { AuthContext, authTokenKey, useAuth } from './hooks/useAuth'
import { AxiosInterceptor } from './components/axiosInterceptor/AxiosInterceptor'
import { buttonStyles } from './styles/theme/_buttonStyles'
import { ChakraProvider, extendTheme, GlobalStyle } from '@chakra-ui/react'
import { colors } from './styles/theme/_colors'
import { globalStyles } from './styles/theme/_globalStyles'
import { headingStyles } from './styles/theme/_headingStyles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { textStyles } from './styles/theme/_textStyles'
import { Translation } from 'react-i18next'

const queryClient = new QueryClient();

function App() {
  const authObject = useAuth(localStorage.getItem(authTokenKey) ?? "");

  const chakraTheme = extendTheme({
    colors: { ...colors },
    components: { Button: buttonStyles, Heading: headingStyles, Text: textStyles },
    styles: globalStyles,
  })

  return (
    <AuthContext.Provider value={authObject}>
      <ChakraProvider theme={chakraTheme}>
        <GlobalStyle />
        <AxiosInterceptor>
          <QueryClientProvider client={queryClient}>
            <Translation>
              {() =>
                <ApplicationRouter />
              }
            </Translation>
          </QueryClientProvider>
        </AxiosInterceptor>
      </ChakraProvider>
    </AuthContext.Provider>
  )
}

export default App;
