import { apiClient } from '../../../api/base/ApiClients'
import { AuthContext } from '../../../hooks/useAuth'
import { Button, ButtonGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { ServiceEvents } from '../../../services'
import { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

async function applicationRefresh() {
  return await apiClient.get("application/refresh");
}

export const SessionGuard = () => {
  const tokenContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [showWarning, setShowWarning] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [logoutCausedByExpire, setLogoutCausedByExpire] = useState(false);

  // Using an empty query key to prevent caching
  const { refetch } = useQuery([], applicationRefresh, { enabled: false });

  useEffect(() => {
    window.addEventListener(ServiceEvents.sessionLogout, sessionLogout);
    window.addEventListener(ServiceEvents.sessionStarted, sessionStarted);
    window.addEventListener(ServiceEvents.sessionWarning, sessionWarning);
  }, []);

  const sessionStarted = () => {
    setShowWarning(false);
    setShowLogout(false);
  }

  const sessionWarning = () => {
    setShowWarning(true);
    setShowLogout(false);
  }

  const sessionLogout = (detail: any) => {
    setShowWarning(false);
    setShowLogout(true);
    setLogoutCausedByExpire(detail.expires > 0);
  }

  const handleClose = () => {

  }

  const handleLogout = () => {
    tokenContext!.signOut();
  }

  const getHeader = () => {
    if (showWarning) return t("Session.Header.Expiring");
    return t("Session.Header.Expired");
  }

  const getMessage = () => {
    if (showWarning) return t("Session.Message.Timeout_Warning");
    if (logoutCausedByExpire) return t("Session.Message.Timeout_Expired");
    return t("Session.Message.Logged_Out");
  }

  return (showLogout || (showWarning && tokenContext.isAuthenticated)) ?
    <>
      <Modal closeOnOverlayClick={false} closeOnEsc={false} isOpen={true} isCentered={true} size="4xl" onClose={() => handleClose()}
      >
        <ModalOverlay />
        <ModalContent >
          <ModalHeader >{getHeader()}</ModalHeader>
          <ModalBody>
            <Text >
              {getMessage()}
            </Text>
          </ModalBody>
          <ModalFooter>
            {showWarning ?
              <ButtonGroup spacing={6}>
                <Button variant="secondary" onClick={handleLogout}>{t("Logout")}</Button>
                <Button variant="primary" onClick={() => refetch()}>{t("Session.Button_Extend")}</Button>
              </ButtonGroup>
              :
              <Button variant="secondary" onClick={handleLogout}>{t("Session.Back_To_Home_Page")}</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
    : <></>
}