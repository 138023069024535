import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

export const MainLayout = () => {
  return (
    <Box position='relative' minHeight='100vh'>
      <Box marginTop={{ base: "64px", xl: "148px" }}>
        <Outlet />
      </Box>
    </Box>
  )
}