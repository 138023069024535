import { base64UrlDecode } from '../utils/Base64UrlDecode'
import { createContext, useEffect, useMemo, useState } from 'react'
import { ensureStorageTokenEventHandler } from '../services/authEventHandlers'
import { ERoles, RolesDashboards, RolesUsers } from '../enums/ERoles'
import { history, routePrefixCustomer, routePrefixDashboard } from '../ApplicationRouter'
import { isRoleMatch } from '../utils/Roles'

export const authTokenKey = "StatusChecker-authtoken";

interface IAuthObject {
  setToken: (token: string) => void;
  isAuthenticated: boolean;
  fullName: string;
  role: string;
  isInRole: (r: ERoles) => boolean;
  isInRoles: (roles: ERoles[]) => boolean;
  isInDashboardRole: () => boolean;
  isInCustomerRole: () => boolean;
  hasRole: () => boolean;
  signOut: () => void;
  redirectToRole: () => string;
  removeAuthToken: () => void;
  token: string;
}

export const splitToken = (token: string) => {
  if (token.length === 0) return []
  return token.split(".");
}

export const parsePayload = (tokenParts: string[]) => {
  if (tokenParts.length !== 3) return { exp: 0 }
  return JSON.parse(base64UrlDecode(tokenParts[1]));
}

export const useAuth = (initToken: string) => {
  const [token, setTokenState] = useState(initToken);

  useEffect(() => {
    setToken(initToken);
  }, [initToken])

  const tokenParts = useMemo(() => {
    return splitToken(token);
  }, [token]);

  const tokenPayload = useMemo(() => {
    return parsePayload(tokenParts);
  }, [tokenParts])

  const isDateValid = useMemo(() => {
    if (tokenPayload.FullName !== undefined) {
      return Math.ceil(Date.now() / 1000) <= tokenPayload.exp;
    }
    return false;
  }, [tokenPayload])

  const isAuthenticated = useMemo(
    () => {
      return isDateValid;
    },
    [isDateValid],
  );

  const fullName = useMemo(() => {
    if (tokenPayload && isAuthenticated) {
      return tokenPayload["FullName"].split(",")[0];
    }
    return "";
  }, [tokenPayload, isAuthenticated]);

  const role = useMemo(() => {
    if (tokenPayload && isAuthenticated) {
      return tokenPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].toLowerCase()
    }
    return "";
  }, [tokenPayload, isAuthenticated])

  function isInRole(r: ERoles) {
    return (role && r) && isRoleMatch(role, r.toLowerCase());
  }

  function isInRoles(roles: ERoles[]) {
    return (roles && role) && roles.some((r: ERoles) => isRoleMatch(role, r.toLowerCase()));
  }

  function removeAuthToken() {
    localStorage.removeItem(authTokenKey);
    setTokenState("");
  }

  function hasRole() {
    return role.length > 0;
  }

  function signOut() {
    removeAuthToken();
    history.push("/signout");
  }

  function redirectToRole() {
    if (hasRole()) {
      if (isInRoles(RolesUsers)) {
        return routePrefixCustomer;
      }
      else if (isInRoles(RolesDashboards)) {
        return routePrefixDashboard;
      }
    }
    return "/";
  }

  function isInDashboardRole() {
    if (isAuthenticated) {
      if (isInRoles(RolesDashboards)) {
        return true;
      }
    }
    return false;
  }

  function isInCustomerRole() {
    if (isAuthenticated) {
      if (isInRoles(RolesUsers)) {
        return true;
      }
    }
    return false;
  }

  function setToken(token: string) {
    ensureStorageTokenEventHandler();
    setTokenState(token);
    localStorage.setItem(authTokenKey, ""); // The event handler does not handle changes if the value being set in storage is the same as the current value.
    localStorage.setItem(authTokenKey, token);
  }

  const authObject: IAuthObject = {
    isAuthenticated,
    fullName,
    role,
    isInRole,
    isInRoles,
    isInCustomerRole,
    isInDashboardRole,
    hasRole,
    signOut,
    redirectToRole,
    setToken,
    removeAuthToken,
    token
  }

  return authObject;
}

export const AuthContext = createContext<IAuthObject>({} as IAuthObject);
