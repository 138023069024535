import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { ContactForm } from '../../../../components/secure/contacts/ContactForm'
import { GetContact } from '../../../../api/calls/contacts/ContactQueries'
import { history } from '../../../../ApplicationRouter'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

export const EditContact = () => {
  let { id } = useParams();

  const { data } = useQuery(['contact', id], () => GetContact(id), { enabled: !!id });

  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Edit contact</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        {data && <ContactForm contact={data} />}
      </Box>
    </AutoWidthCenter>
  )
}