import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { Box, Divider, Heading } from '@chakra-ui/react'
import { ERoles } from '../../../enums/ERoles'
import { RenderGuard } from '../../../components/common/guards/RenderGuard'
import { WebsiteStatistics } from './WebsiteStatistics'

export const DashboardHome = () => {
  return (
    <Box pt="5">
      <AutoWidthCenter>
        <Heading as="h1">Dashboard</Heading>
        <Divider />
        <RenderGuard roles={ERoles.ADMIN}>
          <WebsiteStatistics />
        </RenderGuard>
      </AutoWidthCenter>
    </Box>
  )
}