import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Box, Button, Divider, Heading, HStack } from '@chakra-ui/react'
import { history } from '../../../../ApplicationRouter'
import { WebsiteForm } from '../../../../components/secure/website/WebsiteForm'

export const CreateWebsite = () => {
  return (
    <AutoWidthCenter>
      <Box py="5">
        <HStack pb="5" justifyContent="space-between">
          <Heading as="h1">Create website</Heading>
          <Button onClick={() => history.back()}>Back</Button>
        </HStack>
        <Divider />
        <WebsiteForm />
      </Box>
    </AutoWidthCenter>
  )
}