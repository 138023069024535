import { Box, Button, Flex, useToast, VStack } from '@chakra-ui/react'
import { ContactFormValues, IContact } from '../../../models/IContact'
import { ContactValidationSchema } from './Contact.Validation'
import { Form, Formik } from 'formik'
import { history } from '../../../ApplicationRouter'
import { InputControl } from '../../common/formik-chakra'
import { useCreateContactMutation, useEditContactMutation } from '../../../api/calls/contacts/ContactQueries'
import { useEffect, useState } from 'react'

interface IProps {
  contact?: IContact;
}

export const ContactForm = ({ contact }: IProps) => {
  const [formValues, setFormValues] = useState(new ContactFormValues())
  const handleCreateContact = useCreateContactMutation();
  const handleEditContact = useEditContactMutation();
  const toast = useToast();

  useEffect(() => {
    if (contact) setFormValues(new ContactFormValues(contact));
  }, [contact])

  const handleSubmit = async (values: ContactFormValues) => {
    if (contact) {
      handleEditContact.mutate({ id: contact.id, values: values }, {
        onSuccess() {
          history.back();
          toast({
            title: "Contact Edited",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error editing contact",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateContact.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: "Contact created",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error creating contact",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={ContactValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="name" label="Contact name" />
                <InputControl name="email" label="Contact email" />
                <InputControl name="phoneNumber" label="Phone number starting with country-code (no ' ' or '+' or '-')" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  )
}