import { FieldProps } from 'formik'
import { Input, InputProps } from '@chakra-ui/react'

interface TextInputProps {
  chakraProps: InputProps
}

interface IProps extends FieldProps<string, InputProps>, TextInputProps { }

export const TextInput = (props: IProps) => {
  const {
    field,
    form: { handleChange },
  } = props;

  return (
    <Input id={field.name} {...props.chakraProps} onChange={handleChange}></Input>
  )
}
