import { Box, Spinner, Text } from '@chakra-ui/react'
import { GetWebsiteStatisticsQuery } from '../../../api/calls/website/WebsiteStatisticQueries'
import { useQuery } from 'react-query'
import { WebsiteStatistic } from '../../../components/secure/statistic/WebsiteStatistic'

export const WebsiteStatistics = () => {
  const amountOfDays = 90;
  const { data, isLoading } = useQuery({
    queryKey: ["website/stats/", amountOfDays],
    queryFn: () => GetWebsiteStatisticsQuery(amountOfDays),
    staleTime: 15 * 60 * 1000, // Stale time of 15 mins
  });

  return (
    <Box as="section">
      <Text marginBottom="20px">List of websites</Text>
      {isLoading && <Spinner />}
      {(!isLoading && (data === undefined || data.length === 0)) ? <Text>No data found</Text> : (
        data?.map((website, index) => (
          <WebsiteStatistic website={website} amountOfDays={amountOfDays} key={`${website.name}-${index}`} />
        ))
      )}
    </Box>
  )
}