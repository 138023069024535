import { DashboardHome } from '../../pages/secure/dashboard/DashboardHome'
import { ERoles } from '../../enums/ERoles'
import { IRoute } from '../../models/routes/IRoute'

export const GeneralRoutes: IRoute[] = [
  {
    element: <DashboardHome />,
    path: "",
    permission: [ERoles.ADMIN],
    title: "Dashboard",
  },
]