import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Button, Divider, Heading, HStack, Text } from '@chakra-ui/react'
import { DefaultContactTable } from '../../../../components/secure/contacts/DefaultContactTable'
import { NavLink } from 'react-router-dom'

export const DefaultContacts = () => {

  return (
    <AutoWidthCenter>
      <HStack pb="5" mt="5" justifyContent="space-between">
        <Heading as="h1">Default contacts</Heading>
        <Button as={NavLink} to="new">New default contact</Button>
      </HStack>
      <Text>The below default contacts are used for all websites, new or existing</Text>
      <Text>Adding a new default contact will add it to already existing websites</Text>
      <Divider />
      <DefaultContactTable />
    </AutoWidthCenter>
  )
}