import { EStatusCheck } from '../../enums/EStatusCheck'

export interface IWebsite {
  id: string;
  name: string;
  url: string;
  endpoint: string;
  serviceEndpoint: string;
  SmsShortName: string;
  statusChecks: IStatusCheck[];
}

export interface IWebsiteGroupedStats {
  id: string;
  name: string;
  url: string;
  groupedStatusChecks: Record<string, IStatusCheck[]>
}

export interface IStatusCheck {
  id: string;
  websiteId: string;
  dateCreated: Date;
  status: EStatusCheck;
  message: string;
}

export class WebsiteFormValues {
  name: string = "";
  url: string = "";
  endpoint: string = "";
  serviceEndpoint: string = "";
  smsShortName: string = "";
  constructor(init?: IWebsite) {
    Object.assign(this, init);
  }
}

export type WebsiteFormValuesWithId = {
  id: string;
  values: WebsiteFormValues;
}
