import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { Box, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <AutoWidthCenter>
      <Box paddingTop={{ base: "20px" }}>
        <Heading as="h1">
          {t('Error_Not_Found')}
        </Heading>
      </Box>
    </AutoWidthCenter>
  )
}
