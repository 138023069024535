import { Box, Button, Flex, useToast, VStack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { history } from '../../../ApplicationRouter'
import { InputControl } from '../../common/formik-chakra'
import { IWebsite, WebsiteFormValues } from '../../../models/website/IWebsite'
import { useCreateWebsiteMutation, useEditWebsiteMutation } from '../../../api/calls/website/WebsiteStatisticQueries'
import { useEffect, useState } from 'react'
import { WebsiteValidationSchema } from './Website.Validation'

interface IProps {
  website?: IWebsite;
}

export const WebsiteForm = ({ website }: IProps) => {
  const [formValues, setFormValues] = useState(new WebsiteFormValues())
  const handleCreateWebsite = useCreateWebsiteMutation();
  const handleEditWebsite = useEditWebsiteMutation();
  const toast = useToast();

  useEffect(() => {
    if (website) setFormValues(new WebsiteFormValues(website))
  }, [website])

  const handleSubmit = async (values: WebsiteFormValues) => {
    if (website) {
      handleEditWebsite.mutate({ id: website.id, values: values }, {
        onSuccess() {
          history.back();
          toast({
            title: "Website Edited",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error editing website",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateWebsite.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: "Website created",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error creating website",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={WebsiteValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="name" label="Website name" />
                <InputControl name="smsShortName" label="Website name for SMS messages" />
                <InputControl name="url" label="Website url" />
                <InputControl name="endpoint" label="Website endpoint" />
                <InputControl name="serviceEndpoint" label="Website service endpoint" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  )
}