import { createContext, useContext, useReducer } from 'react'

type Action =
  | { type: 'open', payload: any }
  | { type: 'close' }

type Dispatch = (action: Action) => void

type State = {
  open: boolean,
  title: string,
  bodyContent: React.ReactNode,
  footerContent: React.ReactNode,
}

const initialState: State = {
  open: false,
  title: '',
  bodyContent: null,
  footerContent: null,
}

type ModalProviderProps = { children: React.ReactNode }

const ModalStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function modalReducer(state: State, action: Action) {
  switch (action.type) {
    case 'open':
      return {
        open: true,
        title: action.payload.title,
        bodyContent: action.payload.bodyContent,
        footerContent: action.payload.footerContent
      }
    case 'close':
      return {
        open: false,
        title: '',
        bodyContent: null,
        footerContent: null,
      }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

function ModalProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(modalReducer, initialState)
  const value = { state, dispatch }

  return (
    <ModalStateContext.Provider value={value} >
      {children}
    </ModalStateContext.Provider>
  )
}

function useModal() {
  const context = useContext(ModalStateContext);

  if (context === undefined) {
    throw new Error('UseModal must be used wintin a ModalProvider')
  }

  return context
}

export { ModalProvider, useModal }
