import { apiClient } from '../../base/ApiClients'
import { ContactsForWebsiteFormValuesWithId, IContactsForWebsite } from '../../../models/IContact'
import { IWebsite, IWebsiteGroupedStats, WebsiteFormValues, WebsiteFormValuesWithId } from '../../../models/website/IWebsite'
import { useMutation } from 'react-query'

export const GetWebsiteQuery = async (id: any) => {
  const { data } = await apiClient.get<IWebsite>(`website/${id}`);
  return data;
}

export const GetWebsiteStatisticsQuery = async (days: number) => {
  const { data } = await apiClient.get<IWebsiteGroupedStats[]>(`website/stats/${days}`);
  return data;
}

const CreateWebsite = async (values: WebsiteFormValues) => {
  return await apiClient.post('website', values)
}

export const useCreateWebsiteMutation = () => useMutation(CreateWebsite, {});

const EditWebsite = async (values: WebsiteFormValuesWithId) => {
  return await apiClient.put(`website/${values.id}`, values.values);
}

export const useEditWebsiteMutation = () => useMutation(EditWebsite, {});

const DeleteWebsite = async (id: string) => {
  return await apiClient.delete(`website/${id}`)
}

export const useDeleteWebsiteMutation = () => useMutation(DeleteWebsite, {});

export const GetContactsForWebsiteQuery = async (id: any) => {
  const { data } = await apiClient.get<IContactsForWebsite>(`website/${id}/contacts`);
  return data;
}

const UpdateContactsForWebsite = async (values: ContactsForWebsiteFormValuesWithId) => {
  return await apiClient.post(`website/${values.id}/contacts`, values.values);
}

export const useUpdateContactsForWebsiteMutation = () => useMutation(UpdateContactsForWebsite, {});
