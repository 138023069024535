import { Box, Button, Heading, HStack, useToast } from '@chakra-ui/react'
import { CheckboxContainer, ICheckboxItem } from '../../common/formik-chakra'
import { ContactsForWebsiteFormValues, IContactsForWebsite } from '../../../models/IContact'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useUpdateContactsForWebsiteMutation } from '../../../api/calls/website/WebsiteStatisticQueries'

interface IProps {
  websiteId: string;
  contactsForWebsite: IContactsForWebsite;
}

export const WebsiteContactForm = ({ websiteId, contactsForWebsite }: IProps) => {
  const [formValues, setFormValues] = useState(new ContactsForWebsiteFormValues());
  const [contacts, setContacts] = useState<ICheckboxItem[]>([]);
  const [defaultContacts, setDefaultContacts] = useState<ICheckboxItem[]>([]);
  const handleUpdateContacts = useUpdateContactsForWebsiteMutation();
  const toast = useToast();

  useEffect(() => {
    setFormValues(contactsForWebsite);
    let contacts = contactsForWebsite.allContacts.map((contact) => {
      let item: ICheckboxItem = { key: contact.id, name: `${contact.name} - ${contact.email}`, value: contact.id }
      return item;
    })
    setContacts(contacts);
    let defaultContacts = contactsForWebsite.allDefaultContacts.map((defaultContact) => {
      let item: ICheckboxItem = { key: defaultContact.id, name: `${defaultContact.name} - ${defaultContact.email}`, value: defaultContact.id }
      return item;
    })
    setDefaultContacts(defaultContacts);
  }, [contactsForWebsite])

  const handleSubmit = (values: ContactsForWebsiteFormValues) => {
    handleUpdateContacts.mutate({ id: websiteId, values: values }, {
      onSuccess() {
        toast({
          title: "Contacts updated",
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
          position: "top",
        })
      },
      onError() {
        toast({
          title: "Error updating contacts",
          status: "error",
          duration: 10 * 1000,
          isClosable: true,
          position: "top"
        })
      }
    })
  }

  return (
    <>
      <Heading size="lg">Edit contacts for website</Heading>
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <HStack pb="5" justifyContent="space-between">
                <CheckboxContainer label="Default contacts" name="defaultContacts" options={defaultContacts} />
                <CheckboxContainer label="Contacts" name="contacts" options={contacts} />
              </HStack>
              <Box width="100%" pt="3">
                <Button float="right" type="submit">Submit</Button>
              </Box>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  )
}