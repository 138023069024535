import { AutoWidthCenter } from '../../../../components/layout/AutoWidthCenter'
import { Button, Divider, Heading, HStack, Text } from '@chakra-ui/react'
import { ContactTable } from '../../../../components/secure/contacts/ContactTable'
import { NavLink } from 'react-router-dom'

export const Contacts = () => {

  return (
    <AutoWidthCenter>
      <HStack pb="5" mt="5" justifyContent="space-between">
        <Heading as="h1">Contacts</Heading>
        <Button as={NavLink} to="new">New contact</Button>
      </HStack>
      <Text>The below contacts can be assigned to individual websites</Text>
      <Divider />
      <ContactTable />
    </AutoWidthCenter>
  )
}