import { Box } from '@chakra-ui/react'
import { formatDateTime } from '../../../utils/General'
import { IStatusCheck } from '../../../models/website/IWebsite'

interface IProps {
  errorStatuses: IStatusCheck[]
}

export const ErrorBody = ({ errorStatuses }: IProps) => {

  return (
    <Box>
      Total errors: {errorStatuses.length}
      {errorStatuses.map((status, index) => {
        return (
          <p key={index}>{formatDateTime(status.dateCreated, "i18n.Formatted.DateTime")}</p>
        )
      })}
    </Box>
  )
}