import { ComponentSingleStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const headingStyles: ComponentSingleStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => ({
    color: props.colorMode === 'dark' ? 'whiteAlpha.600' : "blackAlpha.700"
  }),
  variants: {
    "href": {
      cursor: "pointer"
    },
  }
}