import { AuthContext } from '../../../hooks/useAuth'
import { useContext } from 'react'

export const RenderGuard = (props: any) => {
  const tokenContext = useContext(AuthContext);
  if (!tokenContext.isAuthenticated) return null;

  return tokenContext.isInRoles(props.roles.split(","))
    ? props.children
    : null;
}