import { Box, Button, Flex, useToast, VStack } from '@chakra-ui/react'
import { DefaultContactFormValues, IDefaultContact } from '../../../models/IContact'
import { DefaultContactValidationSchema } from './DefaultContact.Validation'
import { Form, Formik } from 'formik'
import { history } from '../../../ApplicationRouter'
import { InputControl } from '../../common/formik-chakra'
import { useCreateDefaultContactMutation, useEditDefaultContactMutation } from '../../../api/calls/contacts/ContactQueries'
import { useEffect, useState } from 'react'

interface IProps {
  defaultContact?: IDefaultContact;
}

export const DefaultContactForm = ({ defaultContact }: IProps) => {
  const [formValues, setFormValues] = useState(new DefaultContactFormValues())
  const handleCreateDefaultContact = useCreateDefaultContactMutation();
  const handleEditDefaultContact = useEditDefaultContactMutation();
  const toast = useToast();

  useEffect(() => {
    if (defaultContact) setFormValues(new DefaultContactFormValues(defaultContact));
  }, [defaultContact])

  const handleSubmit = async (values: DefaultContactFormValues) => {
    if (defaultContact) {
      handleEditDefaultContact.mutate({ id: defaultContact.id, values: values }, {
        onSuccess() {
          history.back();
          toast({
            title: "Default contact Edited",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error editing default contact",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    } else {
      handleCreateDefaultContact.mutate(values, {
        onSuccess() {
          history.back();
          toast({
            title: "Default contact created",
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
            position: "top",
          })
        },
        onError() {
          toast({
            title: "Error creating default contact",
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      })
    }
  }

  return (
    <Flex align="center" justify="center">
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={DefaultContactValidationSchema}
      >
        {(formik) => (
          <Box p="10" m="6" rounded="lg" maxW="80%" w="80%">
            <Form onSubmit={formik.handleSubmit}>
              <VStack>
                <InputControl name="name" label="Default contact name" />
                <InputControl name="email" label="Default contact email" />
                <InputControl name="phoneNumber" label="Phone number starting with country-code (no ' ' or '+' or '-')" />

                <Box width="100%" pt="3">
                  <Button float="right" type="submit">Submit</Button>
                </Box>
              </VStack>
            </Form>
          </Box>
        )}
      </Formik>
    </Flex>
  )
}