import { apiClient } from '../../base/ApiClients'
import { ContactFormValues, ContactFormValuesWithId, DefaultContactFormValues, DefaultContactFormValuesWithId, IContact, IDefaultContact } from '../../../models/IContact'
import { useMutation } from 'react-query'

// Default contacts
export const GetDefaultContact = async (id: any) => {
  const { data } = await apiClient.get<IDefaultContact>(`website-default-contact/${id}`);
  return data;
}

export const GetDefaultContacts = async () => {
  const { data } = await apiClient.get<IDefaultContact[]>('website-default-contact');
  return data;
}

const CreateDefaultContact = async (values: DefaultContactFormValues) => {
  return await apiClient.post('website-default-contact', values);
}

export const useCreateDefaultContactMutation = () => useMutation(CreateDefaultContact, {});

const EditDefaultContact = async (values: DefaultContactFormValuesWithId) => {
  return await apiClient.put(`website-default-contact/${values.id}`, values.values);
}

export const useEditDefaultContactMutation = () => useMutation(EditDefaultContact, {});

const DeleteDefaultContact = async (id: string) => {
  return await apiClient.delete(`website-default-contact/${id}`)
}

export const useDeleteDefaultContactMutation = () => useMutation(DeleteDefaultContact, {});
// End default contacts

// Contacts
export const GetContact = async (id: any) => {
  const { data } = await apiClient.get<IContact>(`website-contact/${id}`);
  return data;
}

export const GetContacts = async () => {
  const { data } = await apiClient.get<IContact[]>('website-contact');
  return data;
}

const CreateContact = async (values: ContactFormValues) => {
  return await apiClient.post('website-contact', values);
}

export const useCreateContactMutation = () => useMutation(CreateContact, {});

const EditContact = async (values: ContactFormValuesWithId) => {
  return await apiClient.put(`website-contact/${values.id}`, values.values);
}

export const useEditContactMutation = () => useMutation(EditContact, {});

const DeleteContact = async (id: string) => {
  return await apiClient.delete(`website-contact/${id}`)
}

export const useDeleteContactMutation = () => useMutation(DeleteContact, {});
// End contacts