import { StyleFunctionProps } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';

export const globalStyles: Styles = {
  global: (props: StyleFunctionProps) => ({
    "html, body": {
      background: props.colorMode === "dark" ? "primaryBlack" : "primaryWhite",
    },
    "h1, h2": {
      color: props.colorMode === "dark" ? "whiteAlpha.800" : "blackAlpha.800",
    },
    "h3, p, text, b": {
      color: props.colorMode === "dark" ? "whiteAlpha.700" : "blackAlpha.800",
    },
    "a": {
      color: props.colorMode === "dark" ? "primary_DM" : "primary",
    }
  })
}