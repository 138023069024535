export interface IContact {
  id: string
  name: string;
  email: string;
  phoneNumber: string;
}

export interface IDefaultContact extends IContact { }

export class ContactFormValues {
  name: string = "";
  email: string = "";
  phoneNumber: string = "";
  constructor(init?: IDefaultContact) {
    Object.assign(this, init);
  }
}

export type ContactFormValuesWithId = {
  id: string;
  values: ContactFormValues;
}

export class DefaultContactFormValues {
  name: string = "";
  email: string = "";
  phoneNumber: string = "";
  constructor(init?: IDefaultContact) {
    Object.assign(this, init);
  }
}

export type DefaultContactFormValuesWithId = {
  id: string;
  values: DefaultContactFormValues;
}

export interface IContactsForWebsite {
  allContacts: IContact[];
  contacts: string[];
  allDefaultContacts: IDefaultContact[];
  defaultContacts: string[];
}

export class ContactsForWebsiteFormValues {
  contacts: string[] = [];
  defaultContacts: string[] = [];
  constructor(init?: IContactsForWebsite) {
    if (init?.contacts) this.contacts = init?.contacts
    if (init?.defaultContacts) this.defaultContacts = init?.defaultContacts
  }
}

export type ContactsForWebsiteFormValuesWithId = {
  id: string;
  values: ContactsForWebsiteFormValues;
}
