import { AutoWidthCenter } from '../../components/layout/AutoWidthCenter'
import { LoginForm } from './LoginForm'

export const Login = () => {
  return (
    <AutoWidthCenter>
      <LoginForm />
    </AutoWidthCenter>
  )
}
