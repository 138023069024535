import { AuthContext } from '../../hooks/useAuth'
import { Navigate } from 'react-router-dom'
import { useContext } from 'react'

export const Signout = () => {
  const authContext = useContext(AuthContext);
  authContext.removeAuthToken();

  return <Navigate to="/" replace />
}
