import en from './lang/en-GB.json'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { enGB } from 'date-fns/locale'
import { format as formatDate, isDate } from 'date-fns'
import { initReactI18next } from 'react-i18next'

const resources = { "en-GB": en };

const locales: any = { enGB }; // Used to look up the required locale for date formatting

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ["en-GB"],
    cleanCode: true,
    nsSeparator: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      format: (value: any, format: any, lng: any) => {
        if (isDate(value)) {
          const locale = locales[lng];

          if (format === "date") return formatDate(value, "PPP", { locale })
          if (format === "dateTime") return formatDate(value, "Pp", { locale })

          return formatDate(value, format, { locale })
        } else {
          return value;
        }
      },
    }
  });

export default i18n;